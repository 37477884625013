jQuery(document).ready(function ($) {
  var body = $('body');

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    var scroll_distance = $($.attr(this, 'href')).offset().top;

    $('html, body').animate({
      scrollTop: scroll_distance
    }, 500);
  });

  $('.bubble iframe').on('click', function (event) {
    gtag('event', 'video_play_start', {
      'event_label': 'Video promozionale',
      'event_category': 'video_play',
    });

    $.ajax({
      type: 'POST',
      url: window.location.protocol + "//" + window.location.host + "/ac_event.php",
      data: 'ac_event=__click_video&ac_eventdata=iframe_video',
      success: function () {
      },
      error: function () {
      }
    });

    $.fancybox.open($('#dona'));
  });

  $('.donate-button').on('click', function (event) {
    event.preventDefault();

    $.fancybox.close(true);

    gtag('event', 'donate_button_click', {
      'event_label': 'Click pulsante Dona',
      'event_category': 'donate_button',
    });

    $.ajax({
      type: 'POST',
      url: window.location.protocol + "//" + window.location.host + "/ac_event.php",
      data: 'ac_event=__click_dona&ac_eventdata=button_dona',
      success: function () {
      },
      error: function () {
      }
    });

    $.fancybox.open($('#dona'));
  });

  $('.buy-button').on('click', function (event) {
    event.preventDefault();

    gtag('event', 'donate_buy_click', {
      'event_label': 'Click pulsante Acquista',
      'event_category': 'donate_buy',
    });

    $.ajax({
      type: 'POST',
      url: window.location.protocol + "//" + window.location.host + "/ac_event.php",
      data: 'ac_event=__click_buy&ac_eventdata=button_buy',
      success: function () {
      },
      error: function () {
      }
    });

    $.fancybox.open($('#buy'));
  });

  $('.trigger_promise').on('click', function (event) {
    event.preventDefault();

    gtag('event', 'promise_click', {
      'event_label': 'Click link altri metodi',
      'event_category': 'donate_promise',
    });

    $.ajax({
      type: 'POST',
      url: window.location.protocol + "//" + window.location.host + "/ac_event.php",
      data: 'ac_event=__click_promise&ac_eventdata=promise_buy',
      success: function () {
      },
      error: function () {
      }
    });

    $.fancybox.open($('#promise'));
  });

  $('.go-to-form').on('click', function (event) {
    event.preventDefault();

    var scroll_distance = body.offset().top;

    $('html, body').animate({
      scrollTop: scroll_distance
    }, 500);
  });

  $('.form').on('submit', function (e) {
    e.preventDefault();
    var email_address = $('.form input[type="email"]').val();
    if (email_address !== "") {
      $('.form button[type="submit"]').attr('disabled', 'disabled');
      $.ajax({
        type: 'POST',
        url: window.location.protocol + "//" + window.location.host + "/ac_submit.php",
        data: 'ac_email=' + email_address + '&ac_tag=newsletter',
        success: function () {
          gtag('event', 'newsletter_subscription_request', {
            'event_label': 'Iscrizione Newsletter',
            'event_category': 'newsletter_subscription',
          });

          $.ajax({
            type: 'POST',
            url: window.location.protocol + "//" + window.location.host + "/ac_event.php",
            data: 'ac_event=__submit_newsletter&ac_eventdata=newsletter_subscription',
            success: function () {
            },
            error: function () {
            }
          });

          window.location.replace(window.location.protocol + '//' + window.location.host + '/grazie-per-iscrizione.html');
        },
        error: function () {
          $('#response').append('<p>Si è verificato un errore.</p>');
        }
      });
    }
    return false;
  });

  if ($('#promise').length) {
    $('#submit_promise').on('click', function (f) {
      f.preventDefault();

      var email_address = $('#email_promise').val();

      if (email_address !== "") {
        $.ajax({
          type: 'POST',
          url: window.location.protocol + "//" + window.location.host + "/ac_submit.php",
          data: 'ac_email=' + email_address + '&ac_tag=email',
          success: function () {
            $.ajax({
              type: 'POST',
              url: window.location.protocol + "//" + window.location.host + "/ac_submit.php",
              data: 'ac_email=' + email_address + '&ac_tag=promise',
              success: function () {
                console.log('data submitted');
              },
              error: function () {
              }
            });
          },
          error: function () {
          }
        });

        $.fancybox.close(true);
      }
    });
  }

  var PUBLISHABLE_KEY = 'pk_live_ImYqYgc7kXBF1pbq59yA55xK00ObXCSm90';
  //var PUBLISHABLE_KEY = 'pk_test_krAcjRC8FH9s0xWC5YEEufiy00VFJbvZUj';
  var DOMAIN = window.location.origin;
  var stripe = Stripe(PUBLISHABLE_KEY);

  var allowed_country_codes = ['AC','AD','AE','AF','AG','AI','AL','AM','AO','AQ','AR','AT','AU','AW','AX','AZ','BA','BB','BD','BE','BF','BG','BH','BI','BJ','BL','BM','BN','BO','BQ','BR','BS','BT','BV','BW','BY','BZ','CA','CD','CF','CG','CH','CI','CK','CL','CM','CN','CO','CR','CV','CW','CY','CZ','DE','DJ','DK','DM','DO','DZ','EC','EE','EG','EH','ER','ES','ET','FI','FJ','FK','FO','FR','GA','GB','GD','GE','GF','GG','GH','GI','GL','GM','GN','GP','GQ','GR','GS','GT','GU','GW','GY','HK','HN','HR','HT','HU','ID','IE','IL','IM','IN','IO','IQ','IS','IT','JE','JM','JO','JP','KE','KG','KH','KI','KM','KN','KR','KW','KY','KZ','LA','LB','LC','LI','LK','LR','LS','LT','LU','LV','LY','MA','MC','MD','ME','MF','MG','MK','ML','MM','MN','MO','MQ','MR','MS','MT','MU','MV','MW','MX','MY','MZ','NA','NC','NE','NG','NI','NL','NO','NP','NR','NU','NZ','OM','PA','PE','PF','PG','PH','PK','PL','PM','PN','PR','PS','PT','PY','QA','RE','RO','RS','RU','RW','SA','SB','SC','SE','SG','SH','SI','SJ','SK','SL','SM','SN','SO','SR','SS','ST','SV','SX','SZ','TA','TC','TD','TF','TG','TH','TJ','TK','TL','TM','TN','TO','TR','TT','TV','TW','TZ','UA','UG','US','UY','UZ','VA','VC','VE','VG','VN','VU','WF','WS','XK','YE','YT','ZA','ZM','ZW','ZZ'];

  if ($('#dona').length) {
    $('#submit_dona').on('click', function (f) {
      f.preventDefault();

      var email_address = $('#email_dona').val();
      var PRICE_ID = $('#type_dona').val();

      if (email_address !== "") {
        $.ajax({
          type: 'POST',
          url: window.location.protocol + "//" + window.location.host + "/ac_submit.php",
          data: 'ac_email=' + email_address + '&ac_tag=email',
          success: function () {
            console.log('data submitted');
          },
          error: function () {
          }
        });

        $.fancybox.close(true);

        stripe
          .redirectToCheckout({
            customerEmail: email_address,
            mode: 'payment',
            lineItems: [{price: PRICE_ID, quantity: 1}],
            successUrl: DOMAIN + "/grazie-per-donazione.html?session_id={CHECKOUT_SESSION_ID}&customer_email=" + email_address,
            cancelUrl: DOMAIN + "/transazione-cancellata.html?session_id={CHECKOUT_SESSION_ID}",
            //billingAddressCollection: 'required',
          })
          .then(function (result) {
            if (result.error) {
              var displayError = document.getElementById("error-message");
              displayError.textContent = result.error.message;
            }
          });
      }
    });
  }

  if ($('#buy').length) {
    $('#submit_buy').on('click', function (f) {
      f.preventDefault();

      var email_address = $('#email_buy').val();
      var PRICE_ID = $('#type_buy').val();

      if (email_address !== "") {
        $.ajax({
          type: 'POST',
          url: window.location.protocol + "//" + window.location.host + "/ac_submit.php",
          data: 'ac_email=' + email_address + '&ac_tag=email',
          success: function () {
            console.log('data submitted');
          },
          error: function () {
          }
        });

        $.fancybox.close(true);

        stripe
          .redirectToCheckout({
            customerEmail: email_address,
            mode: 'payment',
            lineItems: [{price: PRICE_ID, quantity: 1}],
            successUrl: DOMAIN + "/grazie-per-acquisto.html?session_id={CHECKOUT_SESSION_ID}&customer_email=" + email_address,
            cancelUrl: DOMAIN + "/transazione-cancellata.html?session_id={CHECKOUT_SESSION_ID}",
            billingAddressCollection: 'required',
            shippingAddressCollection: {
              allowedCountries: allowed_country_codes,
            }
          })
          .then(function (result) {
            if (result.error) {
              var displayError = document.getElementById("error-message");
              displayError.textContent = result.error.message;
            }
          });
      }
    });
  }

  var urlParams = new URLSearchParams(window.location.search);

  if ($('#session').length) {
    if (urlParams.has("session_id")) {
      document.getElementById("session").textContent = urlParams.get("session_id");
    }
  }

  if ($('.success.dona').length) {
    if (urlParams.has("customer_email")) {
      var customer_email = urlParams.get("customer_email");

      $.ajax({
        type: 'POST',
        url: window.location.protocol + "//" + window.location.host + "/ac_submit.php",
        data: 'ac_email=' + customer_email + '&ac_tag=donazione',
        success: function () {
        },
        error: function () {
        }
      });
    }
  }

  if ($('.success.buy').length) {
    if (urlParams.has("customer_email")) {
      var customer_email = urlParams.get("customer_email");

      $.ajax({
        type: 'POST',
        url: window.location.protocol + "//" + window.location.host + "/ac_submit.php",
        data: 'ac_email=' + customer_email + '&ac_tag=acquisto',
        success: function () {
        },
        error: function () {
        }
      });
    }
  }
});


